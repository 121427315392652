<template>
  <v-row v-for="account in bank_accounts_summary_data" :key="account.id">
    <v-col cols="12" md="12">
      <v-card link>
        <v-card-item>
          <v-card-title class="text-left" @click="routeToBankAccount(account.id)">{{ account.bank_name }}</v-card-title>
          <v-card-subtitle class="text-left" @click="routeToBankAccount(account.id)">{{ account.account_nickname }}</v-card-subtitle>
          <template v-slot:append>
            <v-btn icon="mdi-upload" variant="text" @click="showFileModal(account)"></v-btn>
            <v-btn icon="mdi-pencil" variant="text" @click="showEditBankAccountModal(account)"></v-btn>
          </template>
        </v-card-item>
        <v-divider></v-divider>
        <v-card-text @click="routeToBankAccount(account.id)">
          <v-container>
            <!-- replace this with something useful
            <v-row>
              <v-col cols="12" md="4" >
                <p class="font-weight-light">last txn</p>
                  <p class="text-h4">1/09/24</p>
              </v-col>
              <v-col cols="12" md="4" >
                <p class="font-weight-light">last import</p>
                  <p class="text-h4">5/09/24</p>
              </v-col>
              <v-col cols="12" md="4" >
                <p class="font-weight-light">some value</p>
                  <p class="text-h4">999</p>
              </v-col>
            </v-row>
            -->
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

<!-- modals -->
<BankTxnsFileModalPopup :show="show_file_modal" @close="show_file_modal = false" :bankAccountId="this.selected_bank_account.id"></BankTxnsFileModalPopup>
<BankAccountEditModalPopup :show="show_edit_bank_account_modal" @close="show_edit_bank_account_modal = false" :bankAccount="this.selected_bank_account" :importStartingDate="this.selected_bank_account_start_import_date"></BankAccountEditModalPopup>

</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import BankTxnsFileModalPopup from './modals/BankTxnsFileModalPopup.vue';
import BankAccountEditModalPopup from './modals/BankAccountEditModalPopup.vue';
import { format } from 'date-fns'

export default {
  components: {
    BankTxnsFileModalPopup,
    BankAccountEditModalPopup

  },
  data() {
    return {
      bank_accounts_summary_data: [],
      show_file_modal: false,
      show_edit_bank_account_modal: false,
      selected_bank_account: {},
      selected_bank_account_start_import_date: ''
    };
  },
  async created() {
    this.getBankAccountsSummaryData();
  },
  computed: {
    ...mapState({
      token: "token"
    })
  },
  methods: {
    async getBankAccountsSummaryData(){
      try {
      const response  = await axios.get("/bank/accounts/",
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
      .then((response) => {
          this.bank_accounts_summary_data = response.data;
      });
      
      } catch (error) {
        console.error(error);
      }
    },
    showFileModal(bank_account){
      this.selected_bank_account = bank_account;
      this.show_file_modal = true;
    },
    showEditBankAccountModal(bank_account){
      this.selected_bank_account = bank_account;

      if(bank_account.starting_txn_import_date){
        this.selected_bank_account_start_import_date = format(bank_account.starting_txn_import_date, "yyyy-MM-dd"); // convert from ISO to vanilla date field so date field in modal understands it
      }
      else{
        this.selected_bank_account_start_import_date = "";
      }
      
      
      // console.log(this.selected_bank_account_start_import_date)
      this.show_edit_bank_account_modal = true;
    },
    routeToBankAccount(id){
      this.$router.push('/bankaccounttxns/' + id)
    },
  }
  
};

</script>


<style scoped>
.text-h4 {
  font-family: 'Courier New', Courier, monospace, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.fade-in-enter-active {
  transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-in-leave-active {
  transition: opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}

.green_text{
  color: green;
}

.red_text{
  color: red;
}

</style>