<template>
  
  <v-navigation-drawer :width="200" class="text-left">

    <v-list>
      <v-list-item link title="dashboard" to="/dashboard"></v-list-item>
      <v-list-group value="asset group">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="assets"></v-list-item>
        </template>
        <v-list-item link title="properties" to="/properties"></v-list-item>
        <v-list-item link title="vehicles" to="/vehicles"></v-list-item>
        <v-list-item link title="cash" to="/cash"></v-list-item>
        <v-list-item link title="loans" to="/loans"></v-list-item>   
        <v-list-item link title="securities" to="/securitiessummary"></v-list-item>
        <v-list-item link title="super funds" to="/superfunds"></v-list-item>   
      </v-list-group>
      <v-list-group value="liability group">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="liabilities"></v-list-item>
        </template>
      </v-list-group>
      <v-list-group value="incoming group">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="incoming"></v-list-item>
        </template>
        <v-list-item link title="salaries" to="/salaries"></v-list-item>
      </v-list-group>
      <v-list-group value="outgoing group">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="outgoing"></v-list-item>
        </template>
        <v-list-item link title="expenses" to="/expenses"></v-list-item>
        <v-list-item link title="banking" to="/bankaccountsummary"></v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>

</template>

<script>


</script>


<style scoped>
/* Style the sidebar - fixed full height */
.sidebar {
  height: 100%;
  width: 160px;
  position: fixed;
  z-index: 1;
  background-color: white;
  overflow-x: hidden;
}

/* Style sidebar links */
.sidebar a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
}

/* Style links on mouse-over */
.sidebar a:hover {
  color: black;
}

/* Add media queries for small screens (when the height of the screen is less than 450px, add a smaller padding and font-size) */
@media screen and (max-height: 450px) {
  .sidebar {padding-top: 15px;}
  .sidebar a {font-size: 18px;}
}

</style>