<template>
  <v-dialog v-model="showModal" width="400" @click:outside=closeDialog>
      <v-card max-width="400">
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="text-h5 text-medium-emphasis ps-2 title-text" >add private loan</div>
          <v-btn icon="mdi-close" variant="text" @click=closeDialog></v-btn>
        </v-card-title>
        <v-divider class="mb-4"></v-divider>
        <v-card-text>
          <v-sheet class="mx-auto" width="300">
            <v-form @submit.prevent>
              <v-text-field v-model="institution" id="institution" :rules="[required]" label="institution" variant="underlined" class="form_field"></v-text-field>
              <v-text-field v-model="loan_type" id="loan_type" :rules="[required]" label="loan type" variant="underlined" class="form_field"></v-text-field>
              <v-text-field v-model="identifier" id="identifier" :rules="[required]" label="identifier" variant="underlined" class="form_field"></v-text-field>
              <v-text-field v-model="balance" id="balance" :rules="[required]" label="balance" variant="underlined" class="form_field"></v-text-field>
              <v-text-field v-model="interest_rate" id="interest_rate" :rules="[required]" label="interest rate" variant="underlined" class="form_field"></v-text-field>
            </v-form>
          </v-sheet>
        </v-card-text>
        <template v-slot:actions>
          <v-btn v-on:click="submitForm" class="mt-2 submit_button" type="submit" block>Submit</v-btn>
        </template>
      </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  props: {
    show: Boolean
  },
  data() {
    return {
      institution: '',
      loan_type: '',
      identifier: '',
      balance: '',
      interest_rate: ''
    }
  },
  computed: {
    ...mapState({
      token: "token"
    }),
    showModal: {
      get () {
        return this.show
      }
    },
  },
  methods: {  
    async submitForm(e) {
      try {
        e.preventDefault();
        const { data } = await axios.request({
          url: "/privateloans/",
          method: "POST",
          headers: {
              Authorization: `Bearer ${this.token}`
          },
          data: {
            institution: this.institution,
            loan_type: this.loan_type,
            identifier: this.identifier,
            balance: this.balance,
            interest_rate: this.interest_rate,
          }
        })
        .then((response) => {
          console.log(response);
          this.resetDataValues(); // clear form fields
          this.$parent.getPrivateLoans(); // update list on local
          this.$emit("close"); // close modal via parent
        });
      } catch (error) {
        console.log(error);
      }
    },
    closeDialog(){
      this.resetDataValues();
      this.$emit('close');
    },
    resetDataValues(){
      this.institution = "";
      this.loan_type = "";
      this.identifier = "";
      this.balance = "";
      this.interest_rate = "";
    },
    required (v) {
        return !!v || 'required'
    },
  }
}
</script>

<style scoped>

.title-text, .body-text, .form_field, .submit_button {
  font-family: 'Courier New', Courier, monospace, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

</style>