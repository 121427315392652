<template>
  <Line
    id="my-chart-id"
    :options="chartOptionsLocal"
    :data="chartData"
  />
</template>

<script>
import { mapState } from 'vuex';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from 'vue-chartjs';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
ChartJS.register(ChartDataLabels); // plugin to configure labels on charts

export default {
  name: 'LineChart',
  components: { Line },
  props: {
    chartData: {
        type: Object,
        required: true
      }
  },
  async created() {
    
  },
  data() {
    return {
      chartOptionsLocal: {
        responsive: true,
        scales: {
          x: {
            type: 'linear',
            title: {
              display: true,
              text: 'years'
            }
          },
          y: {
            ticks: {
              callback: function(value, index, ticks) {
                  return '$' + (value / 1000000) + 'M';
              }
            }
          }
        },   
        plugins: {
          legend: {
                display: false,
            },
           
          datalabels: {
            display: false
          },
          

        }
      },
    }
  },
  computed: {
    ...mapState({
      token: "token"
    }),
  },
  methods: {
    

  }

}
</script>

<style scoped>



</style>