<template>
    
    <v-container>
      <v-app-bar scroll-behavior="elevate">
        <template v-slot:append>
          <!--
          <v-app-bar-title class="">
            <router-link v-if="!isLoggedIn" to="/login" class="mr-5">login</router-link>
            <router-link v-if="isLoggedIn" to="/dashboard" class="mr-5">dashboard</router-link>
          </v-app-bar-title>
          -->
        </template>
      </v-app-bar>
    </v-container>
    
    
    
    <v-container>
      <router-link v-if="isLoggedIn" to="/dashboard" class="text-h1 position-middle-of-page">wellfi</router-link>
      <router-link v-if="!isLoggedIn" to="/login" class="text-h1 position-middle-of-page">wellfi</router-link>
      <!--
      <p class="text-h1 position-middle-of-page" >wellfi</p>
      -->
    </v-container>
    
</template>

<script>
import axios from 'axios';
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
  data() {
    return {
      user_name: '',
      password: '',
    }
  },
  computed: {
    ...mapState({
      token: "token"
    }),
    ...mapGetters(["isLoggedIn"]),
  },
  methods: {
    ...mapMutations(["setUser", "setToken"]),
    async submitForm(e) {
      try {
        e.preventDefault();
        const response = await axios.post(
          "/auth/",
          {
            user_name: this.user_name,
            password: this.password
          });
          const access_token = response.data.access_token;
          const user = response.data.user;

          if(access_token != null){
            this.setUser(user);
            this.setToken(access_token);
            this.$router.push('/dashboard');
          }
          
        
      } catch (error) {
        console.log(error);
      }
      
    }
  } 
}
</script>


<style scoped>
.logo{
  margin-left: 15px;
}

.text-h1{
  font-family: 'Courier New', Courier, monospace, sans-serif;
}

.position-middle-of-page{
  left: 0;
  line-height: 150px;
  margin-top: -100px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
}

a, a:link, a:visited, a:active {
  color: inherit;
  text-decoration: none; 
}







</style>