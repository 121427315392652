<template>
  <div class="operations-container">
    <v-btn icon="mdi-plus" variant="text" @click="showModal = true" id="show-modal"></v-btn>
  </div>
  
  <table>
    <tr>
      <th>id</th>
      <th>institution</th>
      <th>type</th>
      <th>identifier</th>
      <th>balance</th>
      <th>interest rate</th>
      <th>annual interest</th>
    </tr>
    <tr v-for="loan in loans.private_loans" :key="loan.id" @click="showEditModal(loan )">
      <td>{{ loan.id }}</td>
      <td>{{ loan.institution }}</td>
      <td>{{ loan.loan_type }}</td>
      <td>{{ loan.identifier }}</td>
      <td>{{ loan.balance.toLocaleString() }}</td>
      <td>{{ Number(loan.interest_rate).toLocaleString('en-GB', { style: 'percent', minimumFractionDigits:2 }) }}</td>
      <td>{{ loan.annual_interest.toLocaleString() }}</td>
    </tr>
    <tr><td class="empty-cell"></td></tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td class="font-weight-bold">{{ Number(loans.total_balance).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</td>
      <td class="font-weight-bold">{{ Number(loans.total_interest_rate).toLocaleString('en-GB', { style: 'percent', minimumFractionDigits:2 }) }}</td>
      <td class="font-weight-bold">{{ Number(loans.total_annual_interest).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</td>
    </tr>
  </table>
  
  <!-- Modals -->
  <LoansModalPopup :show="showModal" @close="showModal = false"></LoansModalPopup><!-- add a new record -->
  <LoansEditModalPopup :show="showEditModalToggle" :currentRow="selectedRow" @close="showEditModalToggle = false"></LoansEditModalPopup> <!-- edit a record -->



</template>

<script>
import axios from 'axios';
import LoansModalPopup from './modals/LoansNewModalPopup.vue';
import LoansEditModalPopup from './modals/LoansEditModalPopup.vue';
import { mapState } from 'vuex';

export default {
  components: {
    LoansModalPopup,
    LoansEditModalPopup
  },
  data() {
    return {
      loans: [],
      showModal: false,
      showEditModalToggle: false,
      selectedRow: {}
    };
  },
  computed: {
    ...mapState({
      token: "token"
    })
  },
  async created() {
    this.getPrivateLoans();
  },
  methods: {
    showEditModal(row){
      this.selectedRow=row;
      this.showEditModalToggle = true;
    },
    async getPrivateLoans(){
      try {
      const response  = await axios.get("/privateloans/",
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });
      this.loans = response.data;
      } catch (error) {
        console.error(error);
      }
    }
  }
  
};

</script>


<style scoped>
table{
  width: 100%;
}

table tr{
  cursor: pointer;
}

.operations-container{
  float: right;
  margin-right: 10px;
  margin-bottom: 10px;
}

.empty-cell{
  height:10px; 
}
</style>