<template>
  <Doughnut
    id="my-chart-id"
    :options="chartOptionsLocal"
    :data="chartData"
  />
</template>

<script>
import { mapState } from 'vuex';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'vue-chartjs';

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(ChartDataLabels); // plugin to configure labels on charts

export default {
  name: 'PieChart',
  components: { Doughnut },
  props: {
    chartData: {
        type: Object,
        required: true
      }
  },
  async created() {
    
  },
  data() {
    return {
      chartOptionsLocal: {
        responsive: true,
        plugins: {
          legend: {
                display: false,
            },
          datalabels: {
            textAlign: "center",
            color: "white",
            formatter: function(value, context) {
              return context.chart.data.labels[context.dataIndex] + '\n' + value.toFixed(2) + '%'; //  multi line data label
            },
            font: {
              weight: "bold",
              size: 16,
            },
          },

        }
      }
    }
  },
  computed: {
    ...mapState({
      token: "token"
    }),
  },
  methods: {
    

  }

}
</script>

<style scoped>



</style>