<template>
  <div class="operations-container">
    <v-btn icon="mdi-plus" variant="text" @click="showModal = true" id="show-modal"></v-btn>
  </div>
  
  <table>
    <tr>
      <th>id</th>
      <th>identifier</th>
      <th>annual amount</th>
    </tr>
    <tr v-for="salary in salaries.salaries" :key="salary.id" @click="showEditModal(salary )">
      <td>{{ salary.id }}</td>
      <td>{{ salary.identifier }}</td>
      <td>{{ salary.annual_amount.toLocaleString() }}</td>
    </tr>
    <tr><td class="empty-cell"></td></tr>
    <tr>
      <td></td>
      <td></td>
      <td class="font-weight-bold">{{ Number(salaries.total_annual_amount).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</td>
    </tr>

  </table>
  
  <!-- Modals-->
  <SalaryModalPopup :show="showModal" @close="showModal = false"></SalaryModalPopup> <!-- add a new record -->

  <SalaryEditModalPopup :show="showEditModalToggle" :currentRow="selectedRow" @close="showEditModalToggle = false"></SalaryEditModalPopup> <!--edit a record -->

</template>

<script>
import axios from 'axios';
import SalaryModalPopup from './modals/SalaryNewModalPopup.vue';
import SalaryEditModalPopup from './modals/SalaryEditModalPopup.vue';
import { mapState } from 'vuex';

export default {
  components: {
    SalaryModalPopup,
    SalaryEditModalPopup
  },
  data() {
    return {
      salaries: [],
      showModal: false,
      showEditModalToggle: false,
      selectedRow: {}
    };
  },
  async created() {
    this.getSalaries();
  },
  computed: {
    ...mapState({
      token: "token"
    })
  },
  methods: {
    showEditModal(row){
     this.selectedRow=row;
     this.showEditModalToggle = true;
    },
    async getSalaries(){
      try {
      const response  = await axios.get("/salaries/",
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
      .then((response) => {
          this.salaries = response.data;
      });
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>


<style scoped>
table{
  width: 100%;
}

table tr{
  cursor: pointer;
}

.operations-container{
  float: right;
  margin-right: 10px;
  margin-bottom: 10px;
}

.empty-cell{
  height:10px; 
}
</style>