<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card :elevation="1">
        <v-card-item>
          <v-card-title class="text-left">open positions</v-card-title>
          <v-card-subtitle class="text-left">{{ open_position_data.institution }} - {{  open_position_data.account_name }}</v-card-subtitle>
          <template v-slot:append>
            <v-btn v-if="open_position_data.foreign_currency_txns" icon="mdi-currency-usd" :variant="isBaseCurrency ? 'tonal' : 'text'" @click="this.isBaseCurrency = !this.isBaseCurrency"></v-btn>
            <v-btn icon="mdi-refresh" variant="text" @click="refreshSecurityPrices()"></v-btn>
          </template>   
        </v-card-item>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <table>
              <tr>
                <th>ticker</th>
                <th>currency</th>
                <th>units</th>
                <th>unit cost</th>
                <th>cost base</th>
                <th>price</th>
                <th>value</th>
                <th>profit</th>
                <th>profit %</th>
                <th>yield %</th>
                <th>yield $</th>
              </tr>
              <tr v-for="position in open_position_data.open_positions" :key="position.ticker" @click="routeToSecurityPosition(position.ticker)" >
                  <td>{{ position.ticker}}</td>
                <Transition mode="out-in" name="fade-in">
                  <td :key="isBaseCurrency">{{ isBaseCurrency ? position.base_currency.currency : position.currency }}</td>
                </Transition>
                <td>{{ position.units.toLocaleString() }}</td>
                <Transition mode="out-in" name="fade-in">
                  <td :key="isBaseCurrency">{{ (isBaseCurrency ? position.base_currency.running_unit_cost : position.running_unit_cost).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                </Transition>
                <Transition mode="out-in" name="fade-in">
                  <td :key="isBaseCurrency">{{ (isBaseCurrency ? position.base_currency.running_cost_base : position.running_cost_base).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</td>
                </Transition>
                <Transition mode="out-in" name="fade-in">
                  <td :key="isBaseCurrency">{{ (isBaseCurrency ? position.base_currency.price : position.price).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                </Transition>
                <Transition mode="out-in" name="fade-in">
                  <td :key="isBaseCurrency">{{ (isBaseCurrency ? position.base_currency.market_value : position.market_value).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</td>
                </Transition>
                <Transition mode="out-in" name="fade-in">
                  <td :class="getTextColour(isBaseCurrency ? position.base_currency.profit : position.profit)" :key="isBaseCurrency">{{ (isBaseCurrency ? position.base_currency.profit : position.profit).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</td>
                </Transition>
                <Transition mode="out-in" name="fade-in">
                  <td :class="getTextColour(isBaseCurrency ? position.base_currency.profit_pct : position.profit_pct)" :key="isBaseCurrency">{{ (isBaseCurrency ? position.base_currency.profit_pct : position.profit_pct).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                </Transition>
                  <td>{{ position.yield_pct.toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                <Transition mode="out-in" name="fade-in">
                  <td :key="isBaseCurrency">{{ (isBaseCurrency ? position.base_currency.yield_value : position.yield_value).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</td>
                </Transition>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <Transition mode="out-in" name="fade-in">
                  <td :key="isBaseCurrency" class="font-weight-bold">{{ (isBaseCurrency ? Number(open_position_data.base_currency.total_portfolio_cost_base) : Number(open_position_data.total_portfolio_cost_base)).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</td>
                </Transition>
                <td></td>
                <Transition mode="out-in" name="fade-in">
                  <td :key="isBaseCurrency" class="font-weight-bold">{{ (isBaseCurrency ? Number(open_position_data.base_currency.total_market_value) : Number(open_position_data.total_market_value)).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</td>
                </Transition>
                <Transition mode="out-in" name="fade-in">
                  <td :class="getTextColour(isBaseCurrency ?  open_position_data.base_currency.total_portfolio_profit : open_position_data.total_portfolio_profit)" :key="isBaseCurrency" class="font-weight-bold">{{ (isBaseCurrency ?  Number(open_position_data.base_currency.total_portfolio_profit) : Number(open_position_data.total_portfolio_profit)).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</td>
                </Transition>
                <Transition mode="out-in" name="fade-in">
                  <td :class="getTextColour(isBaseCurrency ?  open_position_data.base_currency.total_portfolio_profit_pct : open_position_data.total_portfolio_profit_pct)" :key="isBaseCurrency" class="font-weight-bold">{{ (isBaseCurrency ?  Number(open_position_data.base_currency.total_portfolio_profit_pct) : Number(open_position_data.total_portfolio_profit_pct)).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                </Transition>
                <td class="font-weight-bold">{{ open_position_data.total_portfolio_yield_pct }}</td>
                <Transition mode="out-in" name="fade-in">
                  <td :key="isBaseCurrency" class="font-weight-bold">{{ (isBaseCurrency ? Number(open_position_data.base_currency.total_portfolio_yield_value) : Number(open_position_data.total_portfolio_yield_value)).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</td>
                </Transition>
              </tr>
            </table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="12" md="12">
      <v-card :elevation="1">
        <v-card-item>
          <v-card-title class="text-left">txns</v-card-title>
          <v-card-subtitle class="text-left">{{ open_position_data.institution }} - {{  open_position_data.account_name }}</v-card-subtitle>
          <template v-slot:append>
            <v-btn  v-if="open_position_data.foreign_currency_txns" icon="mdi-currency-usd" :variant="isBaseCurrency ? 'tonal' : 'text'" @click="this.isBaseCurrency = !this.isBaseCurrency"></v-btn>
            <v-btn icon="mdi-upload" variant="text" @click="showFileModal = true" id="show-modal"></v-btn>
            <v-btn icon="mdi-plus" variant="text" @click="showModal = true" id="show-modal"></v-btn>
          </template>  
        </v-card-item>
        <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <table>
                <tr>
                  <th>txn date</th>
                  <th>ticker</th>
                  <th>currency</th>
                  <th>type</th>
                  <th>units</th>
                  <th>price</th>
                  <th>gross amount</th>
                  <th>brokerage</th>
                  <th>net amount</th>
                  <th v-if="open_position_data.foreign_currency_txns">spot fx</th>
                </tr>
                <tr v-for="txn in broker_txns.slice().reverse()" :key="txn.id" @click="showEditModal(txn )">
                  <td>{{ showDateFormat(txn.txn_date) }}</td>
                  <td>{{ txn.ticker }}</td>
                  <Transition mode="out-in" name="fade-in">
                    <td :key="isBaseCurrency">{{ isBaseCurrency ? txn.base_currency.currency : txn.currency }}</td>
                  </Transition>
                  <td>{{ txn.txn_type }}</td>
                  <td>{{ txn.qty.toLocaleString() }}</td>
                  <Transition mode="out-in" name="fade-in">
                    <td :key="isBaseCurrency">{{ (isBaseCurrency ? txn.base_currency.price : txn.price).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                  </Transition>
                  <Transition mode="out-in" name="fade-in">
                    <td :key="isBaseCurrency">{{ (isBaseCurrency ? txn.base_currency.gross_amount : txn.gross_amount).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                  </Transition>
                  <Transition mode="out-in" name="fade-in">
                    <td :key="isBaseCurrency">{{ (isBaseCurrency ? txn.base_currency.brokerage : txn.brokerage).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                  </Transition>
                  <Transition mode="out-in" name="fade-in">
                    <td :key="isBaseCurrency">{{ (isBaseCurrency ? txn.base_currency.net_amount : txn.net_amount).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                  </Transition>
                  <td v-if="txn.currency != 'AUD'">{{ txn.base_currency_spot_rate }}</td>
                </tr>
              </table>
            </v-container>
          </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  

  <!-- txn modals -->
  <BrokerTxnsModalPopup :show="showModal" @close="showModal = false" :brokerAccountId="open_position_data.broker_account_id"></BrokerTxnsModalPopup> <!-- add new record -->
  <BrokerTxnsFileModalPopup :show="showFileModal" @close="showFileModal = false" :brokerAccountId="open_position_data.broker_account_id"></BrokerTxnsFileModalPopup> <!-- import file -->
  <BrokerTxnsEditModalPopup :show="showEditModalToggle" @close="showEditModalToggle = false" :currentRow="selectedRow"></BrokerTxnsEditModalPopup> <!-- edit a record -->
</template>

<script>
import axios from 'axios';
import BrokerTxnsModalPopup from './modals/BrokerTxnsModalPopup.vue'
import BrokerTxnsEditModalPopup from './modals/BrokerTxnsEditModalPopup.vue';
import BrokerTxnsFileModalPopup from './modals/BrokerTxnsFileModalPopup.vue';
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  components: {
    BrokerTxnsModalPopup,
    BrokerTxnsEditModalPopup,
    BrokerTxnsFileModalPopup
  },
  data() {
    return {
      broker_txns: [],
      open_position_data: [],
      showModal: false,
      showEditModalToggle: false,
      showFileModal: false,
      selectedRow: {},
      isBaseCurrency: false
    };
  },
  async created() {
    this.getBrokerTxns();
    this.getOpenPositionData();
  },
  computed: {
    ...mapState({
      token: "token"
    })
  },
  methods: {
    showEditModal(row){
     this.selectedRow=row;
     this.showEditModalToggle = true;
    },
    async getBrokerTxns(){
      try {
      const response  = await axios.get("/securities/txns/brokeraccount/"+this.$route.params.id,
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
      .then((response) => {
          this.broker_txns = response.data;
      });
      
      } catch (error) {
        console.error(error);
      }
    },
  async getOpenPositionData(){
      try {
      const response  = await axios.get("/securities/portfolios/"+this.$route.params.id,
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
      .then((response) => {
          this.open_position_data = response.data;
      });
      
      } catch (error) {
        console.error(error);
      }
    },
    showDateFormat(rawDate){
      return moment(rawDate).format('DD/MM/YYYY');
    },
    async refreshSecurityPrices(){
      try {
        const { data } = await axios.request({
          url: "/securities/prices/"+this.$route.params.id,
          method: "POST",
          headers: {
              Authorization: `Bearer ${this.token}`
          },
          data: {
          }
        })
        .then((response) => {
          this.getOpenPositionData(); // refresh local values
        });
      } catch (error) {
        console.log(error);
      }
    },
    routeToSecurityPosition(ticker){
      this.$router.push('/brokeraccounts/' + this.$route.params.id + '/position/' + ticker);
    },
    getTextColour(value){
      if(value < 0){
        return 'red_text';
      }
      else if(value > 0){
        return 'green_text';
      }
    }
  }
};
</script>


<style scoped>
table{
  width: 100%;
}

table tr{
  cursor: pointer;
}

.fade-in-enter-active {
  transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-in-leave-active {
  transition: opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}

.green_text{
  color: green;
}

.red_text{
  color: red;
}


</style>