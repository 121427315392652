<template>
  <v-row>
    <v-col cols="6" md="6">
      <v-card>
        <v-card-item>
          <v-card-title class="text-left">financial position</v-card-title>
        </v-card-item>
        <v-divider></v-divider>
        <v-card-text class="text-left">
          <v-container>
            <v-row>
              <v-col cols="8" md="8" class="pa-0 sub-heading-text font-weight-bold">assets</v-col>
            </v-row>
            <v-row>
              <v-col cols="8" md="8" class="pa-0 indent-5">properties</v-col>
              <v-col cols="4" md="4" class="pa-0 text-right">{{ Number(balance_sheet_assets.properties).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="8" md="8" class="pa-0 indent-5">vehicles</v-col>
              <v-col cols="4" md="4" class="pa-0 text-right">{{ Number(balance_sheet_assets.vehicles).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="8" md="8" class="pa-0 indent-5">cash</v-col>
              <v-col cols="4" md="4" class="pa-0 text-right">{{ Number(balance_sheet_assets.cash).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="8" md="8" class="pa-0 indent-5">loans</v-col>
              <v-col cols="4" md="4" class="pa-0 text-right">{{ Number(balance_sheet_assets.loans).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="8" md="8" class="pa-0 indent-5">securities</v-col>
              <v-col cols="4" md="4" class="pa-0 text-right">{{ Number(balance_sheet_assets.securities).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="8" md="8" class="pa-0"></v-col>
              <v-col cols="4" md="4" class="pa-0 text-right font-weight-bold">{{ Number(balance_sheet_totals.total_asset_value).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</v-col>
            </v-row>
            <v-row class="padding-top-10">
              <v-col cols="8" md="8" class="pa-0 sub-heading-text font-weight-bold">liabilities</v-col>
              <v-col cols="4" md="4" class="pa-0 text-right font-weight-bold">{{ Number(balance_sheet_totals.total_liability_value).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</v-col>
            </v-row>
            <v-row class="padding-top-10">
              <v-col cols="8" md="8" class="pa-0 sub-heading-text font-weight-bold">equity</v-col>
              <v-col cols="4" md="4" class="pa-0 text-right font-weight-bold">{{ Number(balance_sheet_totals.total_equity_value).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      
      <v-card class="mt-5">
        <v-card-item>
          <v-card-title class="text-left">asset allocation</v-card-title>
        </v-card-item>
        <v-divider></v-divider>
        <v-card-text class="text-left">
          <v-container>
            <AssetAllocationPieChart v-if="gotAssetAllocationData" :chartData="this.asset_allocation_chart_data"/>
          </v-container>
        </v-card-text>
      </v-card>
   
    </v-col>
    <v-col cols="6" md="6">
      <v-card>
        <v-card-item>
          <v-card-title class="text-left">forecast financial performance</v-card-title>
        </v-card-item>
        <v-divider></v-divider>
        <v-card-text class="text-left">
          <v-container>
            <v-row>
              <v-col cols="8" md="8" class="pa-0 sub-heading-text font-weight-bold">revenue</v-col>
            </v-row>
            <v-row v-for="revenue_item in pl_data.revenue" :key="revenue_item">
              <v-col cols="8" md="8" class="pa-0 indent-5">{{ revenue_item.identifier }}</v-col>
              <v-col cols="4" md="4" class="pa-0 text-right">{{ Number(revenue_item.amount).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="8" md="8" class="pa-0"></v-col>
              <v-col cols="4" md="4" class="pa-0 text-right font-weight-bold">{{ Number(pl_data.total_revenue).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</v-col>
            </v-row>
            <v-row class="padding-top-10">
              <v-col cols="8" md="8" class="pa-0 sub-heading-text font-weight-bold">tax({{ Number(pl_data.assumed_tax_rate).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:0}) }})</v-col>
              <v-col cols="4" md="4" class="pa-0 text-right font-weight-bold">{{ Number(pl_data.tax_payable).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</v-col>
            </v-row>
            <v-row class="padding-top-10">
              <v-col cols="8" md="8" class="pa-0 sub-heading-text font-weight-bold">expenses</v-col>
            </v-row>
            <v-row v-for="expense_item in pl_data.expenses" :key="expense_item">
              <v-col cols="8" md="8" class="pa-0 indent-5">{{ expense_item.identifier }}</v-col>
              <v-col cols="4" md="4" class="pa-0 text-right">{{ Number(expense_item.annual_budget).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="8" md="8" class="pa-0"></v-col>
              <v-col cols="4" md="4" class="pa-0 text-right font-weight-bold">{{ Number(pl_data.total_expenses).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</v-col>
            </v-row>
            <v-row class="padding-top-10">
              <v-col cols="8" md="8" class="pa-0 sub-heading-text font-weight-bold">profit</v-col>
              <v-col cols="4" md="4" class="pa-0 text-right font-weight-bold">{{ Number(pl_data.profit).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>

      
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" md="12">
      <v-card class="mt-5">
        <v-card-item>
          <v-card-title class="text-left">portfolio future value</v-card-title>
        </v-card-item>
        <v-divider></v-divider>
        <v-card-text class="text-left">
          <v-container>
            <FutureValueLineChart v-if="gotFutureValueData" :chartData="this.future_value_chart_data"/>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import AssetAllocationPieChart from './charts/DoughnutChart.vue';
import FutureValueLineChart from './charts/LineChart.vue';

export default {
  components: {
    AssetAllocationPieChart,
    FutureValueLineChart

  },
  data() {
    return {
      balance_sheet_totals: {},
      balance_sheet_assets: {},
      pl_data: {},
      asset_allocation_data: {},
      future_value_data: {},
      asset_allocation_chart_data: {
        labels: [],
        datasets: [
          {
            backgroundColor: ['#4dc9f6','#f67019','#f53794','#537bc4','#acc236','#166a8f','#00a950','#58595b','#8549ba'],
            data: []
          }
        ]
      },
      future_value_chart_data: {
        labels: [0, 1, 5, 10, 20, 30],
        datasets: [
          {
            label: 'Future Value',
            
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.4,
            data: []
          }
        ]
      },
      gotAssetAllocationData: false,
      gotFutureValueData: false,
    };
  },
  async created() {
    this.getBalanceSheetData();
    this.getPandLData();
    this.getAssetAllocationData();
    this.getFutureValueData();
  },
  computed: {
    ...mapState({
      token: "token"
    })
  },
  methods: {
    async getBalanceSheetData(){
      try {
      const response  = await axios.get("/reports/balancesheet",
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
      .then((response) => {
          this.balance_sheet_totals = response.data;
          this.balance_sheet_assets = response.data.assets;
      });
      
      } catch (error) {
        console.error(error);
      }
    },
    async getPandLData(){
      try {
      const response  = await axios.get("/reports/p&l",
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
      .then((response) => {
          this.pl_data = response.data;

      });
      
      } catch (error) {
        console.error(error);
      }
    },
    async getAssetAllocationData(){
      try {
      const response  = await axios.get("/reports/assetallocation",
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
      .then((response) => {
          this.asset_allocation_data = response.data;
          this.createAssetAllocationChartDataRequest();

      });
      
      } catch (error) {
        console.error(error);
      }
    },
    async getFutureValueData(){
      try {
      const response  = await axios.get("/reports/futurevalue",
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
      .then((response) => {
          this.future_value_data = response.data;
          this.createFutureValueChartDataRequest();

      });
      
      } catch (error) {
        console.error(error);
      }
    },
    createAssetAllocationChartDataRequest(){
      this.asset_allocation_data.assets.forEach(element => {
        this.asset_allocation_chart_data.labels.push(element.identifier);
        this.asset_allocation_chart_data.datasets[0].data.push((element.allocation) * 100);
      });

      this.gotAssetAllocationData = true;
    },
    createFutureValueChartDataRequest(){
      this.future_value_chart_data.datasets[0].data.push(this.future_value_data.current_portfolio_value);
      this.future_value_chart_data.datasets[0].data.push(this.future_value_data.future_values.year1.net_portfolio_value);
      this.future_value_chart_data.datasets[0].data.push(this.future_value_data.future_values.year5.net_portfolio_value);
      this.future_value_chart_data.datasets[0].data.push(this.future_value_data.future_values.year10.net_portfolio_value);
      this.future_value_chart_data.datasets[0].data.push(this.future_value_data.future_values.year20.net_portfolio_value);
      this.future_value_chart_data.datasets[0].data.push(this.future_value_data.future_values.year30.net_portfolio_value);

      this.gotFutureValueData = true;
    },
    
  }
};


</script>


<style scoped>

.indent-5{
  padding-left: 5px !important;
}

.sub-heading-text{
  font-size: 1.03rem;
}

.padding-top-10{
  padding-top: 10px;
}

</style>