<template>
  <v-row v-for="portfolio in securities_summary_data" :key="portfolio.id">
    <v-col cols="12" md="12">
      <v-card link>
        <v-card-item>
          <v-card-title class="text-left" @click="routeToBrokerAccount(portfolio.id)">{{ portfolio.institution }}</v-card-title>
          <v-card-subtitle class="text-left" @click="routeToBrokerAccount(portfolio.id)">{{ portfolio.account_name }}</v-card-subtitle>
          <template v-slot:append>
            <v-btn v-if="portfolio.foreign_currency_txns" icon="mdi-currency-usd" :variant="isBaseCurrency ? 'tonal' : 'text'" @click="this.isBaseCurrency = !this.isBaseCurrency"></v-btn>
            <v-icon icon="mdi-refresh" @click="refreshSecurityPrices(portfolio.id)" class="refresh-button"></v-icon>
          </template>
        </v-card-item>
        <v-divider></v-divider>
        <v-card-text @click="routeToBrokerAccount(portfolio.id)">
          <v-container>
            <v-row>
              <v-col cols="12" md="4" >
                <p class="font-weight-light">market value</p>
                <Transition mode="out-in" name="fade-in">
                  <p :key="isBaseCurrency" class="text-h4">{{ (isBaseCurrency ? portfolio.base_currency.total_market_value : portfolio.market_value).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</p>
                </Transition>
              </v-col>
              <v-col cols="12" md="4">
                <p class="font-weight-light">cost base</p>
                <Transition mode="out-in" name="fade-in">
                  <p :key="isBaseCurrency" class="text-h4">{{ (isBaseCurrency ? portfolio.base_currency.total_portfolio_cost_base : portfolio.cost_base).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</p>
                </Transition>
              </v-col>
              <v-col cols="12" md="4">
                <p class="font-weight-light">portfolio % yield</p>
                <p class="text-h4">{{ (portfolio.forecast_yield_pct) }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <p class="font-weight-light">total profit</p>
                <Transition mode="out-in" name="fade-in">
                  <p :class="getTextColour(isBaseCurrency ? portfolio.base_currency.total_portfolio_profit : portfolio.total_profit)" :key="isBaseCurrency" class="text-h4">{{ (isBaseCurrency ? portfolio.base_currency.total_portfolio_profit : portfolio.total_profit).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</p>
                </Transition>
              </v-col>
              <v-col cols="12" md="4">
                <p class="font-weight-light">total profit %</p>
                <p :class="getTextColour(isBaseCurrency ? portfolio.base_currency.total_portfolio_profit_pct : portfolio.total_profit_pct)" class="text-h4">{{ (isBaseCurrency ? portfolio.base_currency.total_portfolio_profit_pct : portfolio.total_profit_pct).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
              </v-col>
              <v-col cols="12" md="4">
                <p class="font-weight-light">portfolio $ yield</p>
                <Transition mode="out-in" name="fade-in">
                  <p :key="isBaseCurrency" class="text-h4">{{ (isBaseCurrency ? portfolio.base_currency.total_portfolio_yield_value : portfolio.forecast_yield_value).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</p>
                </Transition>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  components: {

  },
  data() {
    return {
      securities_summary_data: [],
      isBaseCurrency: false
    };
  },
  async created() {
    this.getSecuritiesSummaryData();
  },
  computed: {
    ...mapState({
      token: "token"
    })
  },
  methods: {
    async getSecuritiesSummaryData(){
      try {
      const response  = await axios.get("/securities/portfolios/summary",
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
      .then((response) => {
          this.securities_summary_data = response.data;
      });
      
      } catch (error) {
        console.error(error);
      }
    },
    routeToBrokerAccount(id){
      console.log(id);
      this.$router.push('/brokeraccounts/' + id)
    },
    async refreshSecurityPrices(portfolio){
      try {
        const { data } = await axios.request({
          url: "/securities/prices/"+portfolio,
          method: "POST",
          headers: {
              Authorization: `Bearer ${this.token}`
          },
          data: {
          }
        })
        .then((response) => {
          this.getSecuritiesSummaryData(); // refresh local values
        });
      } catch (error) {
        console.log(error);
      }
    },
    getTextColour(value){
      if(value < 0){
        return 'red_text';
      }
      else if(value > 0){
        return 'green_text';
      }
    }
  }
  
};

</script>


<style scoped>
.text-h4 {
  font-family: 'Courier New', Courier, monospace, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.fade-in-enter-active {
  transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-in-leave-active {
  transition: opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}

.green_text{
  color: green;
}

.red_text{
  color: red;
}

</style>