<template>
    <v-container>
      <v-app-bar scroll-behavior="elevate">
        <template v-slot:prepend>
          <v-app-bar-title class="font-weight-black">
            
          </v-app-bar-title>
        </template>
      </v-app-bar>
    </v-container>
    
    <v-container>
      <router-link to="/" class="">
        <img src='/w_icon_medium.png' class="icon-logo">
      </router-link>
      <v-card class="mx-auto my-8" max-width="344">
        <v-card-text>
          <v-form @submit.prevent>
            <v-text-field label="user name" id="userName" v-model="user_name"></v-text-field>
            <v-text-field label="password" id="password" v-model="password"></v-text-field>
            <v-btn class="mt-2 font-weight-black" type="submit" v-on:click="submitForm">Login</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
import axios from 'axios';
import { mapMutations } from 'vuex';


export default {
  data() {
    return {
      user_name: '',
      password: '',
    }
  },
  methods: {
    ...mapMutations(["setUser", "setToken"]), 
    async submitForm(e) {
      try {
        e.preventDefault();
        const response = await axios.post(
          "/auth/",
          {
            user_name: this.user_name,
            password: this.password
          });
          const access_token = response.data.access_token;
          const user = response.data.user;

          if(access_token != null){
            this.setUser(user);
            this.setToken(access_token);
            this.$router.push('/dashboard');
          }
          
        
      } catch (error) {
        console.log(error);
      }
      
    }
  } 
}
</script>


<style scoped>
.logo{
  
  font-size: 60px;
}

.icon-logo{
  width: 50px;
}


</style>