<template>
  <v-dialog v-model="showModal" width="400" @click:outside=closeDialog>
      <v-card max-width="400">
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="text-h5 text-medium-emphasis ps-2 title-text" >edit cash account</div>
          <v-btn icon="mdi-close" variant="text" @click=closeDialog></v-btn>
        </v-card-title>
        <v-divider class="mb-4"></v-divider>
        <v-card-text>
          <v-sheet class="mx-auto" width="300">
            <v-form @submit.prevent>
              <v-text-field label="id" variant="underlined" class="form_field" v-model="this.currentRow.id" disabled></v-text-field>
              <v-text-field label="txn date" variant="underlined" class="form_field" v-model="this.currentRow.txn_date" disabled></v-text-field>
              <v-text-field label="ticker" variant="underlined" class="form_field" v-model="this.currentRow.ticker" disabled></v-text-field>
              <v-text-field label="txn type" variant="underlined" class="form_field" v-model="this.currentRow.txn_type" disabled></v-text-field>
              <v-text-field label="qty" variant="underlined" class="form_field" v-model="this.currentRow.qty" disabled></v-text-field>
              <v-text-field label="price" variant="underlined" class="form_field" v-model="this.currentRow.price" disabled></v-text-field>
              <v-text-field label="brokerage" variant="underlined" class="form_field" v-model="this.currentRow.brokerage" disabled></v-text-field>
              <v-text-field label="currency" variant="underlined" class="form_field" v-model="this.currentRow.currency" disabled></v-text-field>
            </v-form>
          </v-sheet>
        </v-card-text>
        <template v-slot:actions>
          <v-row align="center" justify="end" class="actionsRow">
            <v-btn v-on:click="submitDeleteRecord" class="mt-2 submit_button" type="submit" block>delete</v-btn>
          </v-row>
        </template>
      </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  props: {
    show: Boolean,
    currentRow: Object
  },
  data() {
    return {
      txn_date: '',
      ticker: '',
      txn_type: '',
      qty: '',
      price: '',
      brokerage: '',
      user_id: ''
    }
  },
  computed: {
    ...mapState({
      token: "token"
    }),
    showModal: {
      get () {
        return this.show
      }
    },
  },
  methods: {  
    /* Edit txns not used
    async submitForm() {
      try {
        const { data } = await axios.post(
          "/brokertxns/",
          {
            txn_date: this.txn_date,
            ticker: this.ticker,
            txn_type: this.txn_type,
            qty: this.qty,
            price: this.price,
            brokerage: this.brokerage,
            user_id: this.user_id
          }
        );
        
      } catch (error) {
        console.log(error);
      }
    },
    */

    async submitDeleteRecord(e) {      
      try {
        e.preventDefault();
        const { data } = await axios.delete('/securities/txns/' + this.currentRow.id,
          {
            headers: {
              Authorization: `Bearer ${this.token}`
          }
        })
        .then((response) => {
          console.log(response);
          this.$parent.getBrokerTxns(); // update list on local
          this.$emit("close"); // close modal via parent
        });

      } catch (error) {
        console.log(error);
      }
    } ,
    closeDialog(){
      // this.resetDataValues();
      this.$parent.getBrokerTxns();
      this.$emit('close');
    },  
  } 
}
</script>

<style scoped>
.title-text, .body-text, .form_field, .submit_button {
  font-family: 'Courier New', Courier, monospace, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.actionsRow{
  margin-bottom: 5px;
}

</style>