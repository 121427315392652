<template>
  <div class="operations-container">
    <v-btn icon="mdi-plus" variant="text" @click="showModal = true" id="show-modal"></v-btn>
  </div>

  <table>
    <tr>
      <th>id</th>
      <th>account name</th>
      <th>bank</th>
      <th>account type</th>
      <th>balance</th>
      <th>interest rate</th>
      <th>annual interest </th>
    </tr>
    <tr v-for="cash_account in cash_accounts.cash_accounts" :key="cash_account.id" @click="showEditModal(cash_account )">
      <td>{{ cash_account.id }}</td>
      <td>{{ cash_account.account_name }}</td>
      <td>{{ cash_account.bank }}</td>
      <td>{{ cash_account.account_type }}</td>
      <td>{{ cash_account.balance.toLocaleString() }}</td>
      <td>{{ Number(cash_account.interest_rate).toLocaleString('en-GB', { style: 'percent', minimumFractionDigits:2 }) }}</td>
      <td>{{ cash_account.annual_interest.toLocaleString() }}</td>
    </tr>
    <tr><td class="empty-cell"></td></tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td class="font-weight-bold">{{ Number(cash_accounts.total_balance).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</td>
      <td class="font-weight-bold">{{ Number(cash_accounts.total_interest_rate).toLocaleString('en-GB', { style: 'percent', minimumFractionDigits:2 }) }}</td>
      <td class="font-weight-bold">{{ Number(cash_accounts.total_annual_interest).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</td>
    </tr>

  </table>

  <!-- Modals-->
  <CashModalPopup :show="showModal" @close="showModal = false"></CashModalPopup> <!-- add a new record -->
  
  <CashEditModalPopup :show="showEditModalToggle" :currentRow="selectedRow" @close="showEditModalToggle = false"></CashEditModalPopup> <!-- edit a record -->

</template>

<script>
import axios from 'axios';
import CashModalPopup from './modals/CashNewModalPopup.vue';
import CashEditModalPopup from './modals/CashEditModalPopup.vue';
import { mapState } from 'vuex';

export default {
  components: {
    CashModalPopup,
    CashEditModalPopup
  },
  data() {
    return {
      cash_accounts: [],
      showModal: false,
      showEditModalToggle: false,
      selectedRow: {},
      dialog: false,
    };
  },
  async created() {
    this.getCashAccounts();
  },
  computed: {
    ...mapState({
      token: "token"
    })
  },
  methods: {
    showEditModal(row){
     this.selectedRow=row;
     this.showEditModalToggle = true;
    },
    async getCashAccounts(){
      try {
      const response  = await axios.get("/cashaccounts/",
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
      .then((response) => {
          console.log(response);
          this.cash_accounts = response.data;
      });
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>


<style scoped>
table{
  width: 100%;
}

table tr{
  cursor: pointer;
}

.operations-container{
  float: right;
  margin-right: 10px;
  margin-bottom: 10px;
}

.empty-cell{
  height:10px; 
}
</style>