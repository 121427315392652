<template>
  <div class="operations-container">
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn icon="mdi-plus" variant="text" v-bind="props"></v-btn>
      </template>
      <v-list>
        <v-list-item @click="showNewExpenseGroupModal = true" id="show-modal" class="add-expenses-link">
          <v-list-item-title>group</v-list-item-title>
        </v-list-item>
        <v-list-item @click="showNewExpenseCategoryModal = true" id="show-modal" class="add-expenses-link">
          <v-list-item-title>category</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>

  <table>
    <tr>
      <th></th>
      <th>annual budget</th>
      <th>RR</th>
      <th>YTD</th>
      <th>LTM</th>
    </tr>
    <template v-for="expense_group in expenses.expense_groups" :key="expense_group.id">
      <tr>
        <td class="text-left font-weight-bold" @click="showEditExpenseGroupModal(expense_group)">{{ expense_group.identifier }}</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr v-for="expense_category in expense_group.expense_categories" :key="expense_category.id">
        <td class="text-left indent-5" @click="showEditExpenseCategoryModal(expense_category)">{{ expense_category.identifier }}</td>
        <td class="" @click="showEditExpenseCategoryModal(expense_category)">{{ expense_category.annual_budget.toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</td>
        <td :class="getTextColourForExpensePercentages(expense_category.tracking.run_rate_pct)">{{ expense_category.tracking.run_rate_pct ? (expense_category.tracking.run_rate_pct + '%') : '' }}</td>
        <td>{{ expense_category.tracking.year_to_date_pct ? (expense_category.tracking.year_to_date_pct + '%') : '' }}</td>
        <td>{{ expense_category.tracking.last_12_months ? (expense_category.tracking.last_12_months + ' /') : '' }}</td>
      </tr>
      <tr>
        <td></td>
        <td class="text-decoration-overline">{{ expense_group.total_annual_budget.toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </template>
    <tr class="">
      <td></td>
      <td class="grand-total-text font-weight-bold padding-top-10">{{ Number(expenses.total_annual_budget).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</td>
      <td></td>
    </tr>
  </table>

  
  <!-- Modals-->
  <ExpenseGoupNewModalPopup :show="showNewExpenseGroupModal" @close="showNewExpenseGroupModal = false"></ExpenseGoupNewModalPopup> <!-- add a new record -->
  <ExpenseCategoryNewModalPopup :show="showNewExpenseCategoryModal" @close="showNewExpenseCategoryModal = false"></ExpenseCategoryNewModalPopup> <!-- add a new record -->

  <ExpenseGroupEditModalPopup :show="showEditExpenseGroupModalToggle" :currentRow="selectedRow" @close="showEditExpenseGroupModalToggle = false"></ExpenseGroupEditModalPopup> <!--edit a record -->
  <ExpenseCategoryEditModalPopup :show="showEditExpenseCategoryModalToggle" :currentRow="selectedRow" @close="showEditExpenseCategoryModalToggle = false"></ExpenseCategoryEditModalPopup> <!--edit a record -->


</template>

<script>
import axios from 'axios';
import ExpenseGoupNewModalPopup from './modals/ExpenseGoupNewModalPopup.vue';
import ExpenseCategoryNewModalPopup from './modals/ExpenseCategoryNewModalPopup.vue';
import ExpenseGroupEditModalPopup from './modals/ExpenseGroupEditModalPopup.vue';
import ExpenseCategoryEditModalPopup from './modals/ExpenseCategoryEditModalPopup.vue';
import { mapState } from 'vuex';

export default {
  components: {
    ExpenseGoupNewModalPopup,
    ExpenseCategoryNewModalPopup,
    ExpenseGroupEditModalPopup,
    ExpenseCategoryEditModalPopup
  },
  data() {
    return {
      expenses: [],
      showNewExpenseGroupModal: false,
      showNewExpenseCategoryModal: false,
      showEditExpenseGroupModalToggle: false,
      showEditExpenseCategoryModalToggle: false,
      selectedRow: {}
    };
  },
  async created() {
    this.getExpenses();
  },
  computed: {
    ...mapState({
      token: "token"
    })
  },
  methods: {
    showEditExpenseGroupModal(row){
     this.selectedRow=row;
     this.showEditExpenseGroupModalToggle = true;
    },
    showEditExpenseCategoryModal(row){
     this.selectedRow=row;
     this.showEditExpenseCategoryModalToggle = true;
    },
    async getExpenses(){
      try {
      const response  = await axios.get("/expenses/categories/bygroup/",
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
      .then((response) => {
          this.expenses = response.data;
      });
      } catch (error) {
        console.error(error);
      }
    },
    getTextColourForExpensePercentages(value){
      if(value <= 100){
        return 'green_text';
      }
      else if(value > 100){
        return 'red_text';
      }
    }
  }
};
</script>


<style scoped>
table{
  width: 100%;
}

table tr{
  cursor: pointer;
}

.operations-container{
  float: right;
  margin-right: 10px;
  margin-bottom: 10px;
}

.empty-cell{
  height:10px; 
}

.indent-5{
  padding-left: 5px !important;
}

.padding-top-10{
  padding: 10px !important;
}

.grand-total-text{
  font-size: 1.3rem;
}

.add-expenses-link{
    font-family: 'Courier New', Courier, monospace, sans-serif;
    cursor: pointer;
}


.green_text{
  color: green;
}

.red_text{
  color: red;
}

</style>