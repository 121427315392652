<template>
  <main>
    <component :is="$route.meta.layout || 'div'">
      <v-main class="pt-4">
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-main>
    </component>
  </main>

</template>

<script>
// Import Pages
import Login from './components/Login.vue'
import Header from './components/Header.vue'
import SideMenu from './components/SideMenu.vue'
import Dashboard from './components/Dashboard.vue'
import Properties from './components/Properties.vue'
import Vehicles from './components/Vehicles.vue'
import Salaries from './components/Salaries.vue'
import Expenses from './components/Expenses.vue'
import Cash from './components/Cash.vue'
import Loans from './components/Loans.vue'
import SuperFunds from './components/SuperFunds.vue'
import BrokerTxns from './components/BrokerAccounts.vue'
import BankAccountSummary from './components/BankAccountSummary.vue'
import BankAccountTxns from './components/BankAccountTxns.vue'
import Homepage from './components/Homepage.vue'

// Import Layouts (don't think I need to)
import BlankLayout from './layouts/BlankLayout.vue'
import AppLayout from './layouts/AppLayout.vue'


export default {
  name: 'App',
  components: {
    Login,
    Header,
    SideMenu,
    Dashboard,
    Properties,
    Vehicles,
    Expenses,
    Salaries,
    Cash,
    Loans,
    SuperFunds,
    BrokerTxns,
    BankAccountSummary,
    BankAccountTxns,
    Homepage
  },
  data() {
    return {
      
    }
  }
}
</script>

<style>
#app {
  font-family: 'Courier New', Courier, monospace, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
