<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card :elevation="1">
        <v-card-item>
          <v-card-title class="text-left">txns</v-card-title>
          <v-card-subtitle class="text-left"> {{ bank_account_summary.bank_name }} - {{ bank_account_summary.account_nickname }}</v-card-subtitle>
          <template v-slot:append>
            <v-btn icon="mdi-upload" variant="text" @click="showFileModal = true"></v-btn>
          </template>  
        </v-card-item>
        <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <table>
                <tr>
                  <th>date</th>
                  <th>description</th>
                  <th>amount</th>
                  <th>merchant</th>
                  
                  <th style="width: 225px">expense category</th>
                  <th style="width: 50px"></th>
                  <th style="width: 50px"></th>
                </tr>
                <tr v-for="txn in bank_txns" :key="txn.id">
                    <td @click="showEditModal(txn)">{{ showDateFormat(txn.txn_date) }}</td>
                    <td @click="showEditModal(txn)">{{ txn.txn_description }}</td>
                    <td @click="showEditModal(txn)">{{ txn.amount }}</td>
                    <td @click="showEditModal(txn)">{{ txn.bank_merchant_name }}</td>
                    
                    <td style="vertical-align: middle">
                      <v-select
                        :bg-color="this.getExpenseCategorySelectColour(txn)"
                        density="comfortable"
                        hide-details
                        class="mb-3"
                        width="max"
                        label=""
                        v-model="selected_expense[txn.id]"
                        :items="this.expenses"
                        item-title="identifier"
                        item-value="id"
                        @update:modelValue="persistExpenseCategory(txn)"
                      ></v-select>
                    </td>
                    <td>
                      <Transition mode="out-in" name="fade-in">
                        <v-btn v-if="enableCheckButton(txn)" density="comfortable" icon="mdi-check" size="large" class="mb-3" @click="persistExpenseCategory(txn)"></v-btn>
                      </Transition>
                    </td>
                    <td>
                      <v-btn density="comfortable" icon="mdi-minus-thick" size="large" class="mb-3" @click="ignoreTxn(txn)"></v-btn>
                    </td>
                  </tr>
              </table>
            </v-container>
          </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  

  <!-- modals -->
  <BankTxnsFileModalForTxnViewPopup :show="showFileModal" @close="showFileModal = false" :bankAccountId="this.bank_account_summary.id"></BankTxnsFileModalForTxnViewPopup> <!-- upload txns file -->
  <BankTxnsEditModalPopup :show="showEditModalToggle" @close="showEditModalToggle = false" :currentRow="selectedRow"></BankTxnsEditModalPopup> <!-- edit a record -->

</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import moment from 'moment';
import BankTxnsFileModalForTxnViewPopup from './modals/BankTxnsFileModalForTxnViewPopup.vue';
import BankTxnsEditModalPopup from './modals/BankTxnsEditModalPopup.vue';

export default {
  components: {
    BankTxnsFileModalForTxnViewPopup,
    BankTxnsEditModalPopup

  },
  data() {
    return {
      bank_txns: [],
      bank_account_summary: {},
      showFileModal: false,
      showEditModalToggle: false,
      selectedRow: {},
      expenses: [],
      selected_expense: {},
      bank_txn_expense_category_state: {},
      bank_txns_page: 1,
    };
  },
  async created() {
    this.getBankAccountSummaryData();
    this.getBankTxns();
    this.getExpenses();
  },
  computed: {
    ...mapState({
      token: "token"
    })
  },
  mounted() {
    this.enableScrollEventListener();
  },
  unmounted() {
    this.disableScrollEventListener();

  },
  methods: {
    async getBankAccountSummaryData(){
      try {
      const response  = await axios.get("/bank/accounts/"+this.$route.params.id,
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
      .then((response) => {
          this.bank_account_summary = response.data;
      });
      
      } catch (error) {
        console.error(error);
      }
    },
    async getBankTxns(){
      try {
        console.log('getting bank txns for bank account id: ' + this.$route.params.id)
      const response  = await axios.get("/bank/txns/bankaccount/"+this.$route.params.id,
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        },
        params: {
          page: this.bank_txns_page
        }
      })
      .then((response) => {

        response.data.forEach(txn => {
          
          if(txn.ignore_for_reconciled != 1){ // filter out txns ignored for reconciliation
          
            this.bank_txns.push(txn);

            // setup state on expense catagory
            if(txn.expense_category_id){ // if an expense category has been set on a bank txn
              this.selected_expense[txn.id] = txn.expense_category_id; // set it in the v-model for that expense category data
              this.bank_txn_expense_category_state[txn.id] = "persisted"; // set the state of expense category data
            } 
            else if(txn.suggested_expense_category_id){ // if a suggested expense category has been identified on a bank txn
              this.selected_expense[txn.id] = txn.suggested_expense_category_id; // set it in the v-model for that txn expense dropdown
              this.bank_txn_expense_category_state[txn.id] = "suggested";
            }
            else{ // the txn does not have an expense category set and does not have a suggested expense category
              this.bank_txn_expense_category_state[txn.id] = "awaiting";
            }

          }

        });

        this.bank_txns_page++; // increment the page count

      });
      
      } catch (error) {
        console.error(error);
      }
    },
    resetLocalBankTxns(){
      this.bank_txns = [];
      this.bank_txns_page = 1
    },
    async getExpenses(){
      try {
      const response  = await axios.get("/expenses/categories/",
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
      .then((response) => {
          this.expenses = response.data;
  
      });
      } catch (error) {
        console.error(error);
      }
    },
    showDateFormat(rawDate){
      return moment(rawDate).format('DD/MM/YY');
    },
    showEditModal(row){
     this.selectedRow=row;
     this.showEditModalToggle = true;
    },
    async persistExpenseCategory(txn){
      try {
        const { data } = await axios.request({
          url: "/bank/txns/expensecategory/" + txn.id,
          method: "PUT",
          headers: {
              Authorization: `Bearer ${this.token}`
          },
          data: {
            expense_category_id: this.selected_expense[txn.id]
          }
        })
        .then((response) => {
          this.bank_txn_expense_category_state[txn.id] = 'persisted' // update the select button state which drive the display of the check button and it's colour
        });
      } catch (error) {
        console.log(error);
      }
    },
    async ignoreTxn(txn){
      try {
        const { data } = await axios.request({
          url: "/bank/txns/ignore/" + txn.id,
          method: "PUT",
          headers: {
              Authorization: `Bearer ${this.token}`
          },
        })
        .then((response) => {

          this.removeTxnFromLocalStorage(response.data);

          // this.bank_txns.splice(this.bank_txns.findIndex(a => a.id === response.data.id) , 1); // remove ignored txn from local storage

        });
      } catch (error) {
        console.log(error);
      }
    },
    enableCheckButton(txn){
      if(this.bank_txn_expense_category_state[txn.id] == "suggested"){ // only show check button when a suggested expense has been made
        return true;
      }
      else{  
        return false;
      }
    },
    getExpenseCategorySelectColour(txn){
      if(this.bank_txn_expense_category_state[txn.id] == "persisted"){
        return "rgba(0, 255, 0, 0.1)"; // green for done
      }
      else if(this.bank_txn_expense_category_state[txn.id] == "suggested"){
        return "rgba(243, 156, 18,0.1)"; // orange for suggested
      }
      else{
        return "rgba(255, 0, 0,0.1)"; // red for needs to be selected
      }
    },
    enableScrollEventListener(){
      // best practce is to add a debounce functon here so this doesnt get too noisy and effect performance.
      window.addEventListener("scroll", this.getNextTxns);
    },
    getNextTxns(){
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
      if (bottomOfWindow) {
        console.log('im at the bottom of page');
        this.getBankTxns();
      }

    },
    disableScrollEventListener() {
      window.removeEventListener("scroll", this.getNextTxns);
    },
    removeTxnFromLocalStorage(txn){

      this.bank_txns.splice(this.bank_txns.findIndex(a => a.id === txn.id) , 1); // remove ignored txn from local storage

    }
  }
};
</script>


<style scoped>
table{
  width: 100%;
}

table tr{
  cursor: pointer;
}

.fade-in-enter-active {
  transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-in-leave-active {
  transition: opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}

.green_text{
  color: green;
}

.red_text{
  color: red;
}


</style>