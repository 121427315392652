<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card :elevation="1">
        <v-card-item>
          <v-card-title class="text-left">{{ this.$route.params.ticker }}</v-card-title>
          <v-card-subtitle class="text-left">{{ response.institution }} - {{ response.account_name }}</v-card-subtitle>
          <template v-slot:append>
            <v-btn v-if="this.currency != 'AUD'" icon="mdi-currency-usd" :variant="isBaseCurrency ? 'tonal' : 'text'" @click="this.isBaseCurrency = !this.isBaseCurrency"></v-btn>
            <v-btn icon="mdi-cash-edit" @click="showModal = true"  variant="text"></v-btn>
            <v-btn icon="mdi-refresh" @click="refreshSecurityPrices()" variant="text"></v-btn>
          </template>
        </v-card-item>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>          
            <v-row>
              <v-col cols="12" md="3" >
                <p class="font-weight-light">units</p>
                <p class="text-h4">{{ Number(position.units).toLocaleString() }}</p>
              </v-col>
              <v-col cols="12" md="3">
                <p class="font-weight-light">price</p>
                <Transition mode="out-in" name="fade-in">
                  <p class="text-h4" :key="isBaseCurrency">{{ (isBaseCurrency ? position.base_currency.price : position.price) }}</p>
                </Transition>
              </v-col>
              <v-col cols="12" md="3">
                <p class="font-weight-light">value</p>
                <Transition mode="out-in" name="fade-in">
                  <p class="text-h4" :key="isBaseCurrency">{{ Number(isBaseCurrency ? position.base_currency.market_value : position.market_value).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</p>
                </Transition>
                </v-col>
              <v-col cols="12" md="3">
                <p class="font-weight-light">yield %</p>
                <p class="text-h4">{{ position.yield_pct }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <p class="font-weight-light">running unit cost</p>
                <Transition mode="out-in" name="fade-in">
                  <p class="text-h4" :key="isBaseCurrency">{{ (isBaseCurrency ? position.base_currency.running_unit_cost : position.running_unit_cost) }}</p>
                </Transition>
              </v-col>
              <v-col cols="12" md="3">
                <p class="font-weight-light">running cost base</p>
                <Transition mode="out-in" name="fade-in">
                  <p class="text-h4" :key="isBaseCurrency">{{ Number(isBaseCurrency ? position.base_currency.running_cost_base : position.running_cost_base).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</p>
                </Transition>
              </v-col>
              <v-col cols="12" md="3">
                <p class="font-weight-light">profit</p>
                <Transition mode="out-in" name="fade-in">
                  <p class="text-h4" :class="getTextColour(isBaseCurrency ? position.base_currency.profit : position.profit)" :key="isBaseCurrency">{{ Number(isBaseCurrency ? position.base_currency.profit : position.profit).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</p>
                </Transition>
              </v-col>
              <v-col cols="12" md="3">
                <p class="font-weight-light">profit %</p>
                <Transition mode="out-in" name="fade-in">
                  <p class="text-h4" :class="getTextColour(isBaseCurrency ? position.base_currency.profit_pct : position.profit_pct)" :key="isBaseCurrency">{{ (isBaseCurrency ? position.base_currency.profit_pct : position.profit_pct) }}</p>
                </Transition>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="12" md="12">
      <v-card :elevation="1">
        <v-card-item>
          <v-card-title class="text-left">txns</v-card-title>
          <v-card-subtitle class="text-left">{{ response.institution }} - {{ response.account_name }}</v-card-subtitle>
          <template v-slot:append>
            <v-btn v-if="this.currency != 'AUD'" icon="mdi-currency-usd" :variant="isBaseCurrency ? 'tonal' : 'text'" @click="this.isBaseCurrency = !this.isBaseCurrency"></v-btn>
          </template>
        </v-card-item>
        <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <table>
                <tr>
                  <th>txn date</th>
                  <th>ticker</th>
                  <th>currency</th>
                  <th>type</th>
                  <th>qty</th>
                  <th>price</th>
                  <th>gross amount</th>
                  <th>brokerage</th>
                  <th>net amount</th>
                  <th v-if="this.currency != 'AUD'">spot fx</th>
                  <th>running unit cost</th>
                </tr>
                
                <tr v-for="txn in broker_txns.slice().reverse()" :key="txn.id">
                  <td>{{ showDateFormat(txn.txn_date) }}</td>
                  <td>{{ txn.ticker }}</td>
                  <Transition mode="out-in" name="fade-in">
                    <td :key="isBaseCurrency">{{ isBaseCurrency ? txn.base_currency.currency : txn.currency }}</td>
                  </Transition>
                  <td>{{ txn.txn_type }}</td>
                  <td>{{ txn.qty.toLocaleString() }}</td>
                  <Transition mode="out-in" name="fade-in">
                    <td :key="isBaseCurrency">{{ (isBaseCurrency ? txn.base_currency.price : txn.price).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                  </Transition>
                  <Transition mode="out-in" name="fade-in">
                    <td :key="isBaseCurrency">{{ (isBaseCurrency ? txn.base_currency.gross_amount : txn.gross_amount).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                  </Transition>
                  <Transition mode="out-in" name="fade-in">
                    <td :key="isBaseCurrency">{{ (isBaseCurrency ? txn.base_currency.brokerage : txn.brokerage).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                  </Transition>
                  <Transition mode="out-in" name="fade-in">
                    <td :key="isBaseCurrency">{{ (isBaseCurrency ? txn.base_currency.net_amount : txn.net_amount).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                  </Transition>
                  <td v-if="this.currency != 'AUD'">{{ txn.base_currency_spot_rate }}</td>
                  <Transition mode="out-in" name="fade-in">
                    <td :key="isBaseCurrency">{{ (isBaseCurrency ? txn.enrichment.running_unit_cost_in_base_currency : txn.enrichment.running_unit_cost).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                  </Transition>
                </tr>
                
              </table>
            </v-container>
          </v-card-text>
      </v-card>
    </v-col>
  </v-row>

  <!-- txn modals -->
  <SecurityPriceManualUpdatePopup :show="showModal" @close="showModal = false" :ticker="this.$route.params.ticker"></SecurityPriceManualUpdatePopup> <!-- add new record -->
  
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import moment from 'moment';
import SecurityPriceManualUpdatePopup from './modals/SecurityPriceManualUpdatePopup.vue';

export default {
  components: {
    SecurityPriceManualUpdatePopup
  },
  data() {
    return {
      response: {},
      position: {},
      currency: "",
      broker_txns: [],
      broker_account: {},
      showModal: false,
      isBaseCurrency: false
    };
  },
  async created() {
    this.getPositionData();
  },
  computed: {
    ...mapState({
      token: "token"
    })
  },
  methods: {
    async getPositionData(){
      try {
      const response  = await axios.get("/securities/portfolios/" + this.$route.params.brokerid + "/positions/" + this.$route.params.ticker,
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
      .then((response) => {
        this.response = response.data;
        this.position  = this.response.open_positions[0];
        this.currency = this.position.currency;          
        this.broker_txns = this.position.txns;
      });
      
      } catch (error) {
        console.error(error);
      }
    },
    showDateFormat(rawDate){
      return moment(rawDate).format('DD/MM/YYYY');
    },
    async refreshSecurityPrices(){
      try {
        const { data } = await axios.request({
          url: "/securities/prices/"+this.$route.params.brokerid,
          method: "POST",
          headers: {
              Authorization: `Bearer ${this.token}`
          },
          data: {
          }
        })
        .then((response) => {
          this.getOpenPositionData(); // refresh local values
        });
      } catch (error) {
        console.log(error);
      }
    },
    getTextColour(value){
      if(value < 0){
        return 'red_text';
      }
      else if(value > 0){
        return 'green_text';
      }
    }
  }
};

</script>


<style scoped>
.text-h4 {
  font-family: 'Courier New', Courier, monospace, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

table{
  width: 100%;
}

table tr{
  cursor: pointer;
}

.fade-in-enter-active {
  transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-in-leave-active {
  transition: opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}

.green_text{
  color: green;
}

.red_text{
  color: red;
}


</style>