<template>
  <v-dialog v-model="showModal" width="400" @click:outside=closeDialog>
      <v-card max-width="400">
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="text-h5 text-medium-emphasis ps-2 title-text" >add broker txn</div>
          <v-btn icon="mdi-close" variant="text" @click=closeDialog></v-btn>
        </v-card-title>
        <v-divider class="mb-4"></v-divider>
        <v-card-text>
          <v-sheet class="mx-auto" width="300">
            <v-form @submit.prevent>
              <!-- <v-text-field v-model="this.brokerAccountId" id="broker_account" label="broker account id" variant="underlined" class="form_field" disabled></v-text-field> --> 
              <!-- <v-text-field v-model="broker_account" id="broker_account" :rules="[required]" label="broker account id" variant="underlined" class="form_field"></v-text-field>-->
              <v-text-field v-model="txn_date" id="txn_date" :rules="[required]" label="txn date" variant="underlined" class="form_field"></v-text-field>
              <v-text-field v-model="ticker" id="ticker" :rules="[required]" label="ticker" variant="underlined" class="form_field"></v-text-field>
              <v-text-field v-model="txn_type" id="txn_type" :rules="[required]" label="txn type" variant="underlined" class="form_field"></v-text-field>
              <v-text-field v-model="qty" id="qty" :rules="[required]" label="qty" variant="underlined" class="form_field"></v-text-field>
              <v-text-field v-model="price" id="price" :rules="[required]" label="price" variant="underlined" class="form_field"></v-text-field>
              <v-text-field v-model="brokerage" id="brokerage" :rules="[required]" label="brokerage" variant="underlined" class="form_field"></v-text-field>
            </v-form>
          </v-sheet>
        </v-card-text>
        <template v-slot:actions>
          <v-btn v-on:click="submitForm" class="mt-2 submit_button" type="submit" block>Submit</v-btn>
        </template>
      </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  props: {
    show: Boolean,
    brokerAccountId: Number
  },
  data() {
    return {
      broker_account: '',
      txn_date: '',
      ticker: '',
      txn_type: '',
      qty: '',
      price: '',
      brokerage: ''
    }
  },
  computed: {
    ...mapState({
      token: "token"
    }),
    showModal: {
      get () {
        return this.show
      }
    },
  },
  methods: {  

    async submitForm(e) {
      try {
        e.preventDefault();
        const { data } = await axios.request({
          url: "/securities/txns",
          method: "POST",
          headers: {
              Authorization: `Bearer ${this.token}`
          },
          data: {
            broker_account: this.brokerAccountId,
            txn_date: this.txn_date,
            ticker: this.ticker,
            txn_type: this.txn_type,
            qty: this.qty,
            price: this.price,
            brokerage: this.brokerage
          }
        })
        .then((response) => {
          this.resetDataValues(); // clear form inputs
          this.$parent.getBrokerTxns(); // update list on local
          this.$emit("close"); // close modal via parent
        });
      } catch (error) {
        console.log(error);
      }
    },
    resetDataValues(){
      // this.broker_account = "";
      this.txn_date = "";
      this.ticker = "";
      this.txn_type = "";
      this.qty = "";
      this.price = "";
      this.brokerage = "";
    },
    closeDialog(){
      this.resetDataValues();
      this.$emit('close');
    },
    required (v) {
        return !!v || 'required'
    },
  }
}
</script>

<style scoped>
.title-text, .body-text, .form_field, .submit_button {
  font-family: 'Courier New', Courier, monospace, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

</style>