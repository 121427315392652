<template>
  
    
        <v-container>
            <v-app-bar scroll-behavior="elevate">
                <template v-slot:prepend>
                    <v-app-bar-title class="font-weight-black">
                        <h1 class="logo">wellfi</h1>
                    </v-app-bar-title>
                </template>
                <v-spacer></v-spacer>
                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" icon>
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-list-item-title>
                                <a @click="logout" id="logoutLink">logout</a>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                
                
            </v-app-bar>
        </v-container>    
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            token: "token"
        })
    },
    methods: {
        ...mapMutations(["setUser", "setToken", "destroyToken"]),
        ...mapGetters(["isLoggedIn"]),
        logout(){
            this.destroyToken();
            this.$router.push('/login');
        }
    }
};
</script>


<style scoped>

#logoutLink{
    font-family: 'Courier New', Courier, monospace, sans-serif;
    cursor: pointer;
}

.logo{
    margin-left: 15px;
}

</style>