<template>
  <div class="operations-container">
    <v-btn icon="mdi-plus" variant="text" @click="showModal = true" id="show-modal"></v-btn>
  </div>
  
  <table>
    <tr>
      <th>id</th>
      <th>identifier</th>
      <th>valuation</th>
      <th>net Income</th>
      <th>annual yield</th>
    </tr>
    <tr v-for="property in properties.properties" :key="property.id" @click="showEditModal(property )">
      <td>{{ property.id }}</td>
      <td>{{ property.identifier }}</td>
      <td>{{ property.valuation.toLocaleString() }}</td>
      <td>{{ property.net_income.toLocaleString() }}</td>
      <td>{{ Number(property.annual_yield).toLocaleString('en-GB', { style: 'percent', minimumFractionDigits:1 }) }}</td>
    </tr>
    <tr><td class="empty-cell"></td></tr>
    <tr>
      <td></td>
      <td></td>
      <td class="font-weight-bold">{{ Number(properties.total_valuation).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</td>
      <td class="font-weight-bold">{{ Number(properties.total_net_income).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</td>
      <td class="font-weight-bold">{{ Number(properties.total_annual_yield).toLocaleString('en-GB', { style: 'percent', minimumFractionDigits:2 }) }}</td>
    </tr>

  </table>
  
  <!-- Modals-->
  <PropertyModalPopup :show="showModal" @close="showModal = false"></PropertyModalPopup> <!-- add a new record -->

  <PropertyEditModalPopup :show="showEditModalToggle" :currentRow="selectedRow" @close="showEditModalToggle = false"></PropertyEditModalPopup> <!--edit a record -->

</template>

<script>
import axios from 'axios';
import PropertyModalPopup from './modals/PropertyNewModalPopup.vue';
import PropertyEditModalPopup from './modals/PropertyEditModalPopup.vue';
import { mapState } from 'vuex';

export default {
  components: {
    PropertyModalPopup,
    PropertyEditModalPopup
  },
  data() {
    return {
      properties: [],
      showModal: false,
      showEditModalToggle: false,
      selectedRow: {}
    };
  },
  async created() {
    this.getProperties();
  },
  computed: {
    ...mapState({
      token: "token"
    })
  },
  methods: {
    showEditModal(row){
     this.selectedRow=row;
     this.showEditModalToggle = true;
    },
    async getProperties(){
      try {
      const response  = await axios.get("/properties/",
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
      .then((response) => {
          this.properties = response.data;
      });
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>


<style scoped>
table{
  width: 100%;
}

table tr{
  cursor: pointer;
}

.operations-container{
  float: right;
  margin-right: 10px;
  margin-bottom: 10px;
}

.empty-cell{
  height:10px; 
}
</style>