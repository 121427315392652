<template>
  <v-dialog v-model="showModal" width="400" @click:outside=closeDialog>
      <v-card max-width="400">
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="text-h5 text-medium-emphasis ps-2 title-text">upload bank txns</div>
          <v-btn icon="mdi-close" variant="text" @click=closeDialog></v-btn>
        </v-card-title>
        <v-divider class="mb-4"></v-divider>
        <v-card-text>
          <v-sheet class="mx-auto" width="300">
            <v-form @submit.prevent>
              <v-file-input id="file" v-on:change="selectedFile($event)" :rules="[required]" label="select file" variant="underlined" class="form_field"></v-file-input>
            </v-form>
          </v-sheet>
        </v-card-text>
        <template v-slot:actions>
          <v-btn v-on:click="submitForm" class="mt-2 submit_button" type="submit" block>Submit</v-btn>
        </template>
      </v-card>
  </v-dialog>

  
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  props: {
    show: Boolean,
    bankAccountId: Number
  },
  computed: {
    ...mapState({
      token: "token"
    }),
    showModal: {
      get () {
        return this.show
      }
    },
  },
  data() {
    return {
      file: '',

    }
  },
  methods: {  
    async submitForm() {
      try {
        const { data } = await axios.post(
          "/bank/txns/upload/",
          {
            bank_account: this.bankAccountId,
            file: this.file,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${this.token}`
            }
          }
        )
        .then((response) => {
          this.resetDataValues(); // clear form inputs
          this.$parent.resetLocalBankTxns(); // clear list on local
          this.$parent.getBankTxns(); // update list on local
          this.$emit("close"); // close modal via parent
        });
      } catch (error) {
        console.log(error);
      }
    },
    selectedFile(event) {
      this.file = event.target.files[0];
    },
    closeDialog(){
      this.resetDataValues();
      this.$emit('close');
    },
    resetDataValues(){
      this.file = "";
    },
  } 
}
</script>

<style scoped>
.title-text, .body-text, .form_field, .submit_button {
  font-family: 'Courier New', Courier, monospace, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

</style>