<template>
    <v-app>
      <Header />
      <SideMenu />
      <slot />
    </v-app>
    
  </template>
  
  <script>
  import Header from '../components/Header.vue'
  import SideMenu from '../components/SideMenu.vue'
  
  export default {
    name: 'App',
    components: {
      Header,
      SideMenu
    },
    data() {
      return {
        
      }
    }
  }
  </script>
  
  <style>

  </style>
  