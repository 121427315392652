<template>
  <div class="operations-container">
    <v-btn icon="mdi-plus" variant="text" @click="showModal = true" id="show-modal"></v-btn>
  </div>
  
  <table>
    <tr>
      <th>id</th>
      <th>institution</th>
      <th>account name</th>
      <th>balance</th>
    </tr>
    <tr v-for="super_fund in super_funds.super_funds" :key="super_fund.id" @click="showEditModal(super_fund)">
      <td>{{ super_fund.id }}</td>
      <td>{{ super_fund.institution }}</td>
      <td>{{ super_fund.account_name }}</td>
      <td>{{ (super_fund.balance).toLocaleString() }}</td>
    </tr>
    <tr><td class="empty-cell"></td></tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td class="font-weight-bold">{{ Number(super_funds.total_balance).toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</td>
    </tr>
  </table>
  
  <!-- Modals -->
  <SuperFundsNewModalPopup :show="showModal" @close="showModal = false"></SuperFundsNewModalPopup><!-- add a new record -->
  <SuperFundsEditModalPopup :show="showEditModalToggle" :currentRow="selectedRow" @close="showEditModalToggle = false"></SuperFundsEditModalPopup> <!-- edit a record -->

</template>

<script>
import axios from 'axios';
import SuperFundsNewModalPopup from './modals/SuperFundsNewModalPopup.vue';
import SuperFundsEditModalPopup from './modals/SuperFundsEditModalPopup.vue';
import { mapState } from 'vuex';

export default {
  components: {
    SuperFundsNewModalPopup,
    SuperFundsEditModalPopup
  },
  data() {
    return {
      super_funds: [],
      showModal: false,
      showEditModalToggle: false,
      selectedRow: {}
    };
  },
  computed: {
    ...mapState({
      token: "token"
    })
  },
  async created() {
    this.getSuperFunds();
  },
  methods: {
    showEditModal(row){
      this.selectedRow=row;
      this.showEditModalToggle = true;
    },
    async getSuperFunds(){
      try {
      const response  = await axios.get("/superfunds/",
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });
      this.super_funds = response.data;
      } catch (error) {
        console.error(error);
      }
    }
  }
  
};

</script>


<style scoped>
table{
  width: 100%;
}

table tr{
  cursor: pointer;
}

.operations-container{
  float: right;
  margin-right: 10px;
  margin-bottom: 10px;
}

.empty-cell{
  height:10px; 
}
</style>